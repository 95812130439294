import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
import PortraitsTwo from '../../components/sub/portraitx2.js';
import Image from '../../components/image';
export const frontmatter = {
  title: "Creative Chaos In An Uncertain Time",
  date: "2017-07-21",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20170721-0.jpg",
  path: "2017-07-21-Creative-Chaos",
  teaserText: "Predicting the future, however, has built-in challenges, as our world is inherently full of surprises, which is what makes it so unique, fun, and yes, frightening at times."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Creative Chaos In An Uncertain Time" description="Predicting the future, however, has built-in challenges, as our world is inherently full of surprises, which is what makes it so unique, fun, and yes, frightening at times." author='Roberto Ramos' keywords={['Robots']} />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Mapping Lifestyle Shifts & Opportunities`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`We humans love to assess why something happens and what will come next. It's part of our curiosity code and our need to exercise greater control in our lives. Predicting the future, however, has built-in challenges, as our world is inherently full of surprises, which is what makes it so unique, fun, and yes, frightening at times.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our recent presidential election and the failure of pollsters to predict Trump's win are clear examples of the limitations of nailing down the future. Yet here we find ourselves attempting to assess the repercussions of an unpredictable administration on the fashion and retail industries.`}</MDXTag>
      <MDXTag name="p" components={components}>{`While determining the business impact is more difficult, we can more clearly map out how this new political era will play out in the lifestyle arena. This next chapter involves a heightened version of the dance of opposites we've been seeing during the past few years, as consumer tribes hunker down around their values and lifestyle identities. There will be, of course, unifying forces through technology, sports and entertainment that will create mainstream commercial opportunities.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here is how we see the immediate and near-term impact on lifestyle trends of the newly minted Trump Era.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The Anti-Establishment Mood Continues`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The election reaffirmed an anti-establishment mood across party lines, driven by voters and consumers feeling left behind. This mood includes a high level of distrust of politicians and the media in favor of the opinion of family and friends. That mindset creates opportunities for brands to carve out a deeper connection around people's sense of purpose, and how it can inform more powerful brand experiences. We also see a strong opportunity for niche, challenger brands that give consumers greater freedom.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Radical Brands`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`With America seemingly divided on a wide range of social and policy issues, brands are stepping up to take a stand around issues that speak to their core values. We saw this during the Super Bowl, with Budweiser standing up for immigrants and Nike's rollout of an anthemic campaign focused on equality. Expect to see more of this ethos-based way of standing out and connecting.`}</MDXTag>
      <ImageLandscape imageSrc="20170721-1.jpg" caption="Nike Equality Campaign" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`A Tale of Two Trends`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The cultural pendulum will continue to shift between conservative and progressive versions of America and their lifestyle references. Each side will have a seat at the table, with geographic and sociocultural differences and skews. We feel that the All-American lifestyle and related aesthetic will benefit from Melania and Ivanka's chic country club style. For instance, we expect heritage sports like golf and tennis to become more prevalent in mainstream athleisure and the budding athluxury movement.`}</MDXTag>
      <ImageLandscape imageSrc="20170721-2.jpg" caption="Thom Browne Collection" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Urban Street with a Rebel Touch`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The creative class and youth have always served as the strongest voices of dissent. We've begun to see how these two classes are beginning to channel their energies to keep the current administration accountable. We expect to see a stronger tilt towards a youth-driven, rebellious creative energy coming from urban centers. Related to this movement, we also expect the continued rise of streetwear, statement-driven styles and camouflage /military looks.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Survival Mode`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Tales of global warming, extreme urbanization, hacking and political leaks, among other stress-inducing elements, have put us on the edge, with safety and survival at the forefront of consumers' minds. We see an appetite for things that make us stronger and smarter. On the design side, we expect a continued shift towards performance-enhancing, tech-infused materials that deliver lightness, portability and security as well as style. In terms of experiences, we see a greater interest in smaller, more closed and trusted conversations and environments. Expect to see security brands such as Lifelock become more consumer facing, as utilities deliver extreme peace of mind.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Continued Focus on Value`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The consumer will continue to see life and the economy through a pragmatic lens, and will shop with value as top of mind. We see continued interest in off-price players, both online and brick and mortar. There is an opportunity for brands to more effectively and inspiringly deliver the thrill of the hunt through new forms of engagement such as gamifications, personalized catalogs and offers, and next-gen loyalty programs.`}</MDXTag>
      <ImageLandscape imageSrc="20170721-3.jpg" caption="Off-Price Retail" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Pragmatic Comfort is the New Black`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`We're tired of everything clunky, long, tedious and bureaucratic. We will clamor more than ever for things that are simple, to the point and comfortable. We're seeing this in the continued comfort revolution of the athleisure movement, driven by the cocooning effect of the connected home. We also expect to see continued messaging and design around simplicity.`}</MDXTag>
      <ImageLandscape imageSrc="20170721-4.jpg" caption="Amazon Alexa" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Focus on Meaningfulness`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Partisan noise and extreme digitalization have a taxing effect on consumers who will continue to crave meaningfulness. As a result, we will gravitate towards wellness as a lifestyle, favoring time spent outdoors and indulging in communal activities around passion points. We will crave products that stand for something. The opportunities here abound in the form of design uniqueness, provenance and purpose-driven identity and consumer engagement. Physicality will play a big role in this shift, as consumers will want to touch and feel what your brand offers.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The Power of Things That Bring Us Together`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`While our country seems to be divided across red and blue lines, most Americans may crave a break from the political noise. Experiences that bring us together will take on greater meaning and importance. Think of the unifying power of sports, which allows us to healthily vent and cheer, the uplifting power of music with the resurgence of tours and festivals, and the creative affirmation around games and arts & crafts, both digital and physical in nature. Leader brands will find powerful ways of tapping into the little big things that bring us together, creating cultural and commercial opportunities.`}</MDXTag>
      <MDXTag name="p" components={components}>{`These changes have been already brewing, given the tectonic shifts around how we lead our lives and the greater impact of technology. What changes in the Trump Era, at least in its early phase, will be how uncertainty and passion breed more tension. While neither may be the best recipe for business, they do provide greater creative fodder. So, if nothing else, the world of ideas, lifestyles and commercial innovations promise to be anything but boring.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    